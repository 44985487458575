import { showWarning } from '../utils';
import { Plugin } from '../plugin';
import { getLogger } from '../logger';
const logger = getLogger('py-splashscreen');
const AUTOCLOSE_LOADER_DEPRECATED = `
The setting autoclose_loader is deprecated. Please use the
following instead:<br>
<pre>
&lt;py-config&gt;
[splashscreen]
autoclose = false
&lt;/py-config&gt;
</pre>`;
export class SplashscreenPlugin extends Plugin {
    configure(config) {
        // the officially supported setting is config.splashscreen.autoclose,
        // but we still also support the old config.autoclose_loader (with a
        // deprecation warning)
        this.autoclose = true;
        this.enabled = true;
        if ('autoclose_loader' in config) {
            this.autoclose = config.autoclose_loader;
            showWarning(AUTOCLOSE_LOADER_DEPRECATED, 'html');
        }
        if (config.splashscreen) {
            this.autoclose = config.splashscreen.autoclose ?? true;
            this.enabled = config.splashscreen.enabled ?? true;
        }
    }
    beforeLaunch(config) {
        if (!this.enabled) {
            return;
        }
        // add the splashscreen to the DOM
        logger.info('add py-splashscreen');
        customElements.define('py-splashscreen', PySplashscreen);
        this.elem = document.createElement('py-splashscreen');
        document.body.append(this.elem);
        document.addEventListener('py-status-message', (e) => {
            const msg = e.detail;
            this.elem.log(msg);
        });
    }
    afterStartup(interpreter) {
        if (this.autoclose && this.enabled) {
            this.elem.close();
        }
    }
    onUserError(error) {
        if (this.elem !== undefined && this.enabled) {
            // Remove the splashscreen so users can see the banner better
            this.elem.close();
        }
    }
}
export class PySplashscreen extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.innerHTML = `<div id="pyscript_loading_splash" class="py-overlay">
        <div class="py-pop-up">
        <div class="smooth spinner"></div>
        <div id="pyscript-loading-label" class="label">
          <div id="pyscript-operation-details">
          </div>
        </div>
        </div>
      </div>`;
        this.mount_name = this.id.split('-').join('_');
        this.operation = document.getElementById('pyscript-operation');
        this.details = document.getElementById('pyscript-operation-details');
    }
    log(msg) {
        const newLog = document.createElement('p');
        newLog.innerText = msg;
        this.details.appendChild(newLog);
    }
    close() {
        logger.info('Closing');
        this.remove();
    }
}
