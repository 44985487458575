import { getLogger } from '../logger';
import { robustFetch } from '../fetch';
const logger = getLogger('py-register-widget');
function createWidget(interpreter, name, code, klass) {
    class CustomWidget extends HTMLElement {
        constructor() {
            super();
            this.name = name;
            this.klass = klass;
            this.code = code;
            // attach shadow so we can preserve the element original innerHtml content
            this.shadow = this.attachShadow({ mode: 'open' });
            this.wrapper = document.createElement('slot');
            this.shadow.appendChild(this.wrapper);
        }
        async connectedCallback() {
            await interpreter.runButDontRaise(this.code);
            this.proxyClass = interpreter.globals.get(this.klass);
            this.proxy = this.proxyClass(this);
            this.proxy.connect();
            this.registerWidget();
        }
        registerWidget() {
            logger.info('new widget registered:', this.name);
            interpreter.globals.set(this.id, this.proxy);
        }
    }
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const xPyWidget = customElements.define(name, CustomWidget);
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
export function make_PyWidget(interpreter) {
    class PyWidget extends HTMLElement {
        constructor() {
            super();
            // attach shadow so we can preserve the element original innerHtml content
            this.shadow = this.attachShadow({ mode: 'open' });
            this.wrapper = document.createElement('slot');
            this.shadow.appendChild(this.wrapper);
            this.addAttributes('src', 'name', 'klass');
        }
        addAttributes(...attrs) {
            for (const each of attrs) {
                const property = each === 'src' ? 'source' : each;
                if (this.hasAttribute(each)) {
                    this[property] = this.getAttribute(each);
                }
            }
        }
        async connectedCallback() {
            if (this.id === undefined) {
                throw new ReferenceError(`No id specified for component. Components must have an explicit id. Please use id="" to specify your component id.`);
            }
            const mainDiv = document.createElement('div');
            mainDiv.id = this.id + '-main';
            this.appendChild(mainDiv);
            logger.debug('PyWidget: reading source', this.source);
            this.code = await this.getSourceFromFile(this.source);
            createWidget(interpreter, this.name, this.code, this.klass);
        }
        async getSourceFromFile(s) {
            const response = await robustFetch(s);
            return await response.text();
        }
    }
    return PyWidget;
}
